import "./App.css";
// import { Routes, Route } from "react-router-dom";
import UnderConstruction from "./components/Construction/UnderConstruction";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
   return (
      <>
         <div className="App overflow-hidden">
            <UnderConstruction />
         </div>
      </>
   );
}

export default App;
